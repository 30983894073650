<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-progress-circular :size="70" :width="7" color="teal" indeterminate />
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('auth', ['loginWithOpenIDCallback']),
  },
  async mounted() {
    await this.loginWithOpenIDCallback(this.$route.query)
    this.$router.push('/')
  },
}
</script>
